import { Controller } from 'stimulus';

export default class extends Controller {
  handleClick(){
    const event = new Event('glide:initializer');

    window.setTimeout(() => {
      this.glideElement.dispatchEvent(event);
    }, 1000);
  }

  get glideElement(){
    return document.querySelector('#GlideLazyFor5Cs');
  }
}
